import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ThemeModeComponent } from 'src/app/_metronic/kt/layout';

export type ThemeModeType = 'dark' | 'light' | 'system';

const systemMode = ThemeModeComponent.getSystemMode() as 'light' | 'dark';
const themeModeLSKey = 'kt_theme_mode_value';
const themeMenuModeLSKey = 'kt_theme_mode_menu';

const getThemeModeFromLocalStorage = (lsKey: string): ThemeModeType => {
  if (!localStorage) {
    return 'light';
  }

  const data = localStorage.getItem(lsKey);
  if (!data) {
    return 'light';
  }

  if (data === 'light') {
    return 'light';
  }

  if (data === 'dark') {
    return 'dark';
  }

  return 'system';
};

@Injectable({ providedIn: 'root' })
export class ThemeModeService {

  private readonly _mode$ = new BehaviorSubject<ThemeModeType>(getThemeModeFromLocalStorage(themeModeLSKey));
  private readonly _menuMode$ = new BehaviorSubject<ThemeModeType>(getThemeModeFromLocalStorage(themeMenuModeLSKey));

  public readonly mode$ = this._mode$.asObservable();
  public readonly menuMode$ = this._menuMode$.asObservable();

  public init() {
    this.updateMode(this._mode$.value);
    this.updateMenuMode(this._menuMode$.value);
  }

  public switchMode(_mode: ThemeModeType) {
    if (localStorage) {
      const updatedMode = _mode === 'system' ? systemMode : _mode;
      localStorage.setItem(themeModeLSKey, updatedMode);
      localStorage.setItem(themeMenuModeLSKey, _mode);
    }
    document.location.reload()
  }

  public updateMode(_mode: ThemeModeType) {
    const updatedMode = _mode === 'system' ? systemMode : _mode;
    this._mode$.next(updatedMode);
    // themeModeSwitchHelper(updatedMode)
    if (localStorage) {
      localStorage.setItem(themeModeLSKey, updatedMode);
    }

    document.documentElement.setAttribute('data-theme', updatedMode);
    ThemeModeComponent.init();
  }

  public updateMenuMode(_menuMode: ThemeModeType) {
    this._menuMode$.next(_menuMode);
    if (localStorage) {
      localStorage.setItem(themeMenuModeLSKey, _menuMode);
    }
  }

}
